<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  name: "Permissions",
  page: {
    title: "Permissions",
    meta: [
      {
        name: "Permissions",
        content: appConfig.description
      }
    ]
  },
  components:{
    Layout,PageHeader
  },
  data() {
    return {
      title: "Roles ",
      items: [
        {
          text: "Roles",
          href: "/",
          active:true
        },
      ],
      isTableBusy: false,
      isRoleTableBusy: false,
      tableData: [],
      isEdit: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      daterange: [],
      status: "",
      submitted: false,
      modalTitle:"Add New Permission",
      employeeTypes: [],
      itemTitle: "",
      typeID: "",
      price: "",
      showDismissibleAlert: false,
      loading: false,
      permission: "",
      loginType: "",
      defualtRole: true,
      loginTypeArr: [
        { name: "CloudKitch Admin", id: 1 },
        { name: "Restaurant", id: 2 },
        { name: "Corporate", id: 3 },
        { name: "Principle", id: 4 },
      ],
      roleSelected: false,
      roleName: "",
      selectRestData: "",
      selectRestArr: [],
      selectCorpData: "",
      selectCorpArr: [],
      selectPrinData: "",
      selectPrinArr: [],
      restSelected: false,
      corpSelected: false,
      prinSelected: false,
      restaurant: "",
      getRestID: "",
      corporte: "",
      getCorpID: "",
      principle: "",
      getPrinID: "",
      restArr: [],
      corpArr: [],
      prinArr: [],
      isFliterVisible: false,
      isRestVisible: false,
      isCorpVisible: false,
      isPrinVisible: false,
      loginTypeId: "",
      selectDataID: "",
      roleType: "",
      roleTypeArr: [],
      publicRole: "",
      publicRoleArr: [
        "Yes",
        "No",
      ],
      path: "",
      editRight: "",
      deleteRight: "",
      empTypeData: "",
      compID: "",
      compName: "",
      employeeDataTable: "",
      fields: [
        // {
        //   key: "Action",
        //   sortable: false,
        // },
        {
          key: "name",
          sortable: true,
          class:"align-center"
        },



        // {
        //   key: "configure",
        //   sortable: false,
        // },
        {
          key: "created_at",
          label: "Added On",
          sortable: true,
          class:"align-center"
        },
        {
          key: "updated_at",
          label: "Last Update",
          sortable: true,
          class:"align-center"
        },
      ],
      roleFields: [],
      roleTable: [],
      roleFooter: [],
    }
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
  mounted(){
    this.getPermissions();
  },
  methods:{
    addPermissionModal() {
      this.permission="";
      this.$root.$emit("bv::show::modal", "modal-addPermissions");
    },
    submitForm() {
      this.submitted = true;
      this.loading = true;
      let formData = new FormData();
      formData.append("name", this.permission);
      this.axios
          .post(this.$loggedRole+'/permissions', formData )
          .then((result) => {
            this.loading = false;
            this.permission=  "";
            this.$root.$emit("bv::hide::modal", "modal-addPermissions");
            this.$swal({
              icon:'success',
              text:result.data.message,
            }).then(()=>{
              this.getPermissions();
            });

          })
          .catch((error)=> {
            this.loading = false;
            if(error.response.status===500){
              this.$swal({
                title:'Opps!',
                icon:'error',
                text:"Something went wrong!",
              });
            }
            else{
              this.$swal({
                title:'Opps!',
                icon:'error',
                text:error.response.data.message,
              })
            }

          });

    },

    getPermissions() {
      this.isRoleTableBusy = true;
      this.axios
          .get(this.$loggedRole+"/permissions")
          .then((result) => {
            this.isTableBusy = false;
            this.tableData = result.data.data;
          });

    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="card" style="margin-top: -55px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="col-sm-12 col-md-4 mt-1 " >
            <button  class="btn btn-themeYellow w-md waves-effect waves-light mt-3" @click="addPermissionModal();">
              Add Permission <i class="uil-user-plus"></i></button>
          </div>
          <!-- Filter End -->
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Permissions List</span>
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-4">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select style="margin-left: 5px; margin-right: 5px" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                            &nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-4 row">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                          striped
                          hover
                          outlined
                          bordered
                          :items="tableData"
                          :fields="fields"
                          responsive
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                          :busy="isTableBusy"
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <!--                        <template v-slot:cell(Action)="data">-->
                        <!--                          <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px; cursor:pointer; color: #F3766A;" @click="openModal(data.item.empTypeID, data.item.role)"></i>-->
                        <!--                        </template>-->
                        <template v-slot:cell(role)="data">
                          <span>{{ data.item.name }}</span>
                        </template>

                        <!--                        <template v-slot:cell(entity)="data">-->
                        <!--                          <span>{{ data.item.entity }}</span>-->
                        <!--                        </template>-->
                        <!--                        <template v-slot:cell(configure)="data">-->
                        <!--                          <router-link :to="{ name: 'configureAccessControl', params: { empTypeID: data.item.empTypeID, type: 'view', roleName: data.item.role }, }">-->
                        <!--                            <button class="btn btn-themeBrown" style="padding: 1px 4px; font-size: 12px">-->
                        <!--                              Configure Permissions-->
                        <!--                            </button>-->
                        <!--                          </router-link>-->
                        <!--                        </template>-->
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Table -->
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <b-modal id="modal-addPermissions" :title="modalTitle" title-class="font-18">
      <div>
        <div class="col-md-12">
          <label class="form-label" for="formrow-role-input">Enter Permission: </label>
          <input type="text" class="form-control" id="formrow-role-input" v-model="permission" >
        </div>

      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button :disabled="loading" variant="primary" size="sm" class="col-2 float-right" @click="submitForm()">
            Submit
          </b-button>
          <b-spinner v-if="loading" class="m-2 col-3" variant="primary" role="status"></b-spinner>
        </div>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>

</style>